import Sb from "../abstract/StatefulBehavior";
import classNames from "classnames";
import debounce from "lodash/debounce";

export default class ExpandAccordion extends Sb {
  constructor(el, props, refs) {
    super();

    this.state = {
      open: false,
      height: refs.summary.offsetHeight
    };

    this.el = el;
    this.props = props;
    this.props.initElClass = el.className;
    this.refs = refs;

    this.update();
    this.bindToggle();
    this.bindResize();
  }

  update = () => {
    const { open, height } = this.state;

    this.el.className = classNames(this.props.initElClass, {
      "is-open": open
    });

    this.refs.toggle.setAttribute("aria-expanded", open ? "true" : "false");

    Object.assign(this.el.style, { maxHeight: height + "px" });
  };

  bindToggle() {
    this.refs.toggle.addEventListener("click", this.handleToggle);
  }

  bindResize() {
    const { summary, fullContent } = this.refs;

    const debouncedHeight = debounce(() => {
      const { open } = this.state;
      this.setState({
        height: summary.offsetHeight + (open && fullContent.offsetHeight)
      });
    }, 200);

    window.addEventListener("resize", debouncedHeight);
  }

  handleToggle = () => {
    const { open } = this.state;
    const { summary, fullContent } = this.refs;

    this.setState({
      open: !open,
      height: summary.offsetHeight + (!open && fullContent.offsetHeight)
    });
  };
}
