import Sb from "../abstract/StatefulBehavior";
import classNames from "classnames";
import hoverintent from "hoverintent";

export default class RevealSubmenu extends Sb {
  constructor(el, props, refs) {
    super();

    this.state = {
      hovering: false
    };

    this.el = el;
    this.props = props;
    this.props.initActivatorClass = refs.activator.className;
    this.props.initSubmenuClass = refs.submenu.className;
    this.refs = refs;

    this.timeoutId = null;

    this.bindEvents();
  }

  update = () => {
    const { activator, submenu } = this.refs;
    const { initActivatorClass, initSubmenuClass } = this.props;

    activator.className = classNames(initActivatorClass, {
      "is-hovering": this.state.hovering
    });

    submenu.className = classNames(initSubmenuClass, {
      "is-hovering": this.state.hovering
    });
  };

  bindEvents() {
    const { activator, submenu } = this.refs;

    hoverintent(
      activator,
      () => {
        this.handleHoverIn();
      },
      () => {
        return null;
      }
    ).options({
      handleFocus: true
    });

    submenu.addEventListener("mouseenter", this.handleHoverIn);
    submenu.addEventListener("focusin", this.handleHoverIn);

    activator.addEventListener("mouseleave", this.handleHoverOut);
    activator.addEventListener("blur", this.handleHoverOut);
    submenu.addEventListener("mouseleave", this.handleHoverOut);
    submenu.addEventListener("focusout", this.handleHoverOut);

    activator.addEventListener("touchstart", event => {
      event.preventDefault();
      this.state.hovering ? this.handleHoverOut() : this.handleHoverIn();
    });
  }

  handleHoverIn = event => {
    clearTimeout(this.timeoutId);
    this.setState({ hovering: true });
  };

  handleHoverOut = event => {
    clearTimeout(this.timeoutId);

    this.timeoutId = setTimeout(() => {
      this.setState({ hovering: false });
    }, 300);
  };
}
