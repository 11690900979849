import Sb from "../abstract/StatefulBehavior";
import classNames from "classnames";

export default class ExpandInput extends Sb {
  constructor(el, props, refs) {
    super();

    this.state = {
      open: false
    };

    this.el = el;
    this.props = props;
    this.props.initFormClass = refs.form.className;
    this.refs = refs;

    this.timeoutId = null;

    this.update();
    this.bindEvents();
  }

  update = () => {
    const { form, input } = this.refs;
    const { open, submitHovered } = this.state;

    form.className = classNames(this.props.initFormClass, {
      "is-open": open
    });

    open && input.focus();
  };

  bindEvents() {
    const { form, toggle } = this.refs;

    toggle.addEventListener("click", this.handleClick);

    form.addEventListener("focusin", this.handleFocusIn);
    form.addEventListener("focusout", this.handleFocusOut);
  }

  handleClick = event => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ open: !this.state.open });
  };

  handleFocusIn = () => {
    clearTimeout(this.timeoutId);
  };

  handleFocusOut = () => {
    this.timeoutId = setTimeout(() => {
      this.setState({ open: false });
    }, 100);
  };
}
