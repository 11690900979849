import Sb from "../abstract/StatefulBehavior";
import classNames from "classnames";
import Plyr from "plyr";
import defaultOptions from "./plyrOptions";
import isMobile from "ismobilejs";

export default class PlayVideo extends Sb {
  constructor(el, props, refs) {
    super();

    // A large custom play button is rendered when element is loaded,
    // and default controls are hidden. Once button is clicked, play button
    // hides and controls become visible. State doesn't change after that.
    this.state = {
      activated: false
    };

    this.el = el;
    this.refs = refs;
    this.props = props;
    this.props.initElClass = el.className;
    this.props.initPlayButtonClass = refs.playButton.className;

    const plyrOptions = Object.assign({}, defaultOptions, {
      captions: {
        active: props.captionsactive === "true" ? true : false,
        language: props.captionlanguage
      }
    });
    this.videoPlayer = new Plyr(refs.video, plyrOptions);

    this.update();
    this.bindPlayButton();
    this.checkDevice();
  }

  update = () => {
    const { playButton, controls } = this.refs;
    const { initPlayButtonClass, initElClass } = this.props;

    this.el.className = classNames(initElClass, {
      "controls-visible": this.state.activated,
      "controls-hidden": !this.state.activated
    });

    playButton.className = classNames(initPlayButtonClass, {
      "is-visible": !this.state.activated,
      "is-hidden": this.state.activated
    });
  };

  bindPlayButton() {
    const { playButton } = this.refs;
    playButton.addEventListener("click", event => {
      event.preventDefault();
      event.stopPropagation();
      !this.state.activated && this.handlePlay();
    });
  }

  checkDevice() {
    // Set CSS class in body if iPhone
    // this allows override of Plyr hiding iOS captions,
    // since we need them on iPhone
    // see https://github.com/sampotts/plyr/issues/1233
    if (isMobile.apple.phone) {
      document.body.classList.add("is-iphone");
    }
  }

  handlePlay() {
    this.refs.video.play();
    this.setState({ activated: true });
  }
}
