import Percolator from "../../helpers/Percolator";
import Sb from "../abstract/StatefulBehavior";
import Flickity from "flickity";
import classNames from "classnames";
import ObjectFit from "./ObjectFit";

export default class SlideCards extends Sb {
  constructor(el, props, refs) {
    super();

    const percolator = new Percolator();

    this.state = {
      active: 0
    };

    this.el = el;
    this.refs = refs;
    this.props = props;

    this.start = null;

    // Setup equal heights for flickity
    Flickity.prototype._createResizeClass = function() {
      this.element.classList.add("flickity-resize");
    };

    Flickity.createMethods.push("_createResizeClass");

    const resize = Flickity.prototype.resize;
    Flickity.prototype.resize = function() {
      this.element.classList.remove("flickity-resize");
      resize.call(this);
      this.element.classList.add("flickity-resize");
    };

    // optionally allow running slider behavior on a ref; otherwise use `el`
    const sliderEl = refs.slider ? refs.slider : el;

    // optional props to control slider appearance/functionality
    const {
      autoplay = false,
      hidebuttons = false,
      wraparound = false,
      cssgallerysize = false,
      objectfit = false,
      adaptiveheight = false,
      watchcss = false
    } = props;

    props.duration = props.duration ? props.duration : 4000;
    props.initTextClass = refs.text ? refs.text[0].className : null;

    // optionally instantiate ObjectFit behavior on slides
    // ensures behavior has run prior to Flickity instantiation so that
    // Flickity's inline styles on slides aren't overridden
    if (props.objectfit) {
      this.images = [...this.refs.image].map((image, index) => {
        return percolator.loadBehavior(image, ObjectFit);
      });
    }

    this.slider = new Flickity(sliderEl, {
      cellAlign: "left",
      selectedAttraction: 0.075,
      friction: 0.48,
      pageDots: false,
      prevNextButtons: !hidebuttons,
      wrapAround: wraparound,
      setGallerySize: !cssgallerysize,
      adaptiveHeight: adaptiveheight,
      watchCSS: watchcss,
      arrowShape:
        "M9.418 49.992L61.499 0l13.749 13.193-38.332 36.799 38.332 36.815L61.499 100 9.418 49.992z" // eslint-disable-line
    });

    this.bindPagination();
  }

  bindPagination() {
    const { paginateNext } = this.refs;
    if (!paginateNext) return null;

    const paginationArray = Array.isArray(paginateNext)
      ? paginateNext
      : [paginateNext];

    [...paginationArray].forEach(button => {
      button.addEventListener("click", event => {
        this.slider.next(false, false);
      });
    });
  }
}
