/*
Detect Device

Uses `ismobilejs` library to detect if device is any type of mobile device.

If test returns true, any `mobileEl` refs are hidden from the user.
*/
import Sb from "../abstract/StatefulBehavior";
import isMobile from "ismobilejs";

export default class DetectDevice extends Sb {
  constructor(el, props, refs) {
    super();

    this.state = {
      mobile: false
    };

    this.el = el;
    this.refs = refs;

    if (!refs.mobileEl || !refs.desktopEl) return null;

    this.detectDevice();
  }

  update = () => {
    const { mobileEl, desktopEl } = this.refs;
    const mobileRefArray = Array.isArray(mobileEl) ? mobileEl : [mobileEl];
    const desktopRefArray = Array.isArray(desktopEl) ? desktopEl : [desktopEl];

    mobileRefArray.forEach(el => this.setVisibility(el, true));
    desktopRefArray.forEach(el => this.setVisibility(el, false));
  };

  detectDevice() {
    this.setState({ mobile: isMobile.any });
  }

  setVisibility(el, isMobileRef) {
    const { mobile } = this.state;
    // if on mobile device, hide desktopEl refs
    // else hide mobileEl refs
    const isVisible = isMobileRef ? mobile : !mobile;
    !isVisible && el.setAttribute("hidden", "");
  }
}
