import Sb from "../abstract/StatefulBehavior";

export default class SelectLanguage extends Sb {
  constructor(el, props, refs) {
    super();

    this.state = {
      href: null
    };

    this.el = el;
    this.refs = refs;

    if (!refs.download || !refs.select) return;

    this.bindEvents();
    this.update();
  }

  update = () => {
    const { href } = this.state;

    if (!href) return null;

    this.refs.download.setAttribute("href", href);
  };

  bindEvents() {
    this.refs.select.addEventListener("change", this.changeLink);
  }

  changeLink = event => {
    this.setState({ href: event.target.value });
  };
}
