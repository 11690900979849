export default class TogglePrint {
  constructor(el) {
    this.url = el.getAttribute("href");

    if (!this.url) return null;

    el.addEventListener("click", event => {
      event.preventDefault();
      window.open(this.url).print();
    });
  }
}
