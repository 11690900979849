import Sb from "../abstract/StatefulBehavior";
import classNames from "classnames";
import debounce from "lodash/debounce";

export default class ToggleOverlay extends Sb {
  constructor(el, props, refs) {
    super();
    this.state = {
      open: props.open ? true : false
    };

    this.refs = refs;
    this.props = props;
    this.props.initToggleClass = refs.overlayToggle.className;
    this.props.initOverlayClass = refs.overlay.className;

    this.update();
    this.bindToggle();
    this.bindResize();
  }

  get focusable() {
    return Array.from(
      this.refs.overlay.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])' // eslint-disable-line max-len
      )
    );
  }

  update = () => {
    const { overlayToggle, overlay } = this.refs;
    const { initToggleClass, initOverlayClass } = this.props;

    overlayToggle.className = classNames(initToggleClass, {
      "is-untoggled": !this.state.open,
      "is-toggled": this.state.open
    });

    overlay.className = classNames(initOverlayClass, {
      "is-closed": !this.state.open,
      "is-open": this.state.open
    });

    // disable/enable keyboard tabbing based on state.open
    [].forEach.call(this.focusable, el => {
      el.setAttribute("tabindex", this.state.open ? 0 : -1);
    });
  };

  bindToggle() {
    const { overlayToggle } = this.refs;
    overlayToggle.addEventListener("click", event => {
      event.preventDefault();
      event.stopPropagation();
      this.state.open ? this.closeOverlay() : this.openOverlay();
    });
  }

  bindResize() {
    if (!this.props.mobileoverlay) return null;
    // Set overlay to closed state if at wider viewport width
    const debouncedInnerWidth = debounce(() => {
      if (!this.state.open) return null;

      const isMobileWidth = window.innerWidth < 700;
      !isMobileWidth && this.closeOverlay();
    }, 200);

    window.addEventListener("resize", debouncedInnerWidth);
  }

  trapFocus() {
    // conditionally set focus on firstFocus ref when state is open
    const { trapfocus = false } = this.props;

    if (
      !this.state.open ||
      !trapfocus ||
      !this.focusable ||
      !this.focusable.length
    )
      return;

    this.refs.overlay.addEventListener("transitionend", e => {
      if (e.target !== this.refs.overlay) return null;
      this.focusable[0].focus();
    });
  }

  handleKeyUp = event => {
    event.preventDefault();

    switch (event.keyCode) {
      // ESC or Enter
      case 27:
        this.closeOverlay();
        break;
      // Shift + Tab or Tab
      case 16:
      case 9:
        this.handleTab();
        break;
    }
  };

  handleTab() {
    if (!this.focusable || !this.focusable.length) return false;

    const focusedItem = document.activeElement;
    const focusableIndex = this.focusable.indexOf(focusedItem);

    // if focus leaves focusable items in overlay, close it
    focusableIndex === -1 && this.closeOverlay();
  }

  disableScroll = () => {
    document.body.classList.add("has-scroll-lock");
  };

  enableScroll = () => {
    document.body.classList.remove("has-scroll-lock");
  };

  openOverlay = () => {
    const { overlay } = this.refs;
    window.addEventListener("keyup", this.handleKeyUp);
    this.setState({ open: true });
    this.disableScroll();
    this.trapFocus();
  };

  closeOverlay = () => {
    const { overlay } = this.refs;
    window.removeEventListener("keyup", this.handleKeyUp);
    this.setState({ open: false });
    this.enableScroll();
  };
}
