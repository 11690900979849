import DetectDevice from "./DetectDevice";
import ExpandAccordion from "./ExpandAccordion";
import ExpandInput from "./ExpandInput";
import ObjectFit from "./ObjectFit";
import PlayVideo from "./PlayVideo";
import SelectDownload from "./SelectDownload";
import SlideCards from "./SlideCards";
import TogglePrint from "./TogglePrint";
import ToggleOverlay from "./ToggleOverlay";

export default {
  DetectDevice,
  ExpandAccordion,
  ExpandInput,
  ObjectFit,
  PlayVideo,
  SelectDownload,
  SlideCards,
  ToggleOverlay,
  TogglePrint
};
