/*
Creates and updates the value of some global CSS Custom Properties
to aid in accurate, consistent measurements across browsers

https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
*/
import Sb from "../abstract/StatefulBehavior";
import debounce from "lodash/debounce";

export default class SetCSSProperties extends Sb {
  constructor(el, props, refs) {
    super();

    this.state = {
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight
    };

    this.el = el;
    this.props = props;
    this.refs = refs;

    this.update();
    this.bindResize();
  }

  update = () => {
    const vw = this.state.width * 0.01;
    const vh = this.state.height * 0.01;
    const headerHeight = this.refs.siteHeader.getBoundingClientRect().height;

    document.documentElement.style.setProperty("--vw", `${vw}px`);
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    document.documentElement.style.setProperty(
      "--header-height",
      `${headerHeight}px`
    );
  };

  bindResize() {
    // Set overlay to closed state if at wider viewport width
    const debouncedClientSize = debounce(() => {
      this.setState({
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight
      });
    }, 200);

    window.addEventListener("resize", debouncedClientSize);
  }
}
