/*
Polyfill for CSS property object-fit

Detects feature support in browser. If property is unsupported,
replace <img> with a <div> and load src as a background image
*/
import Sb from "../abstract/StatefulBehavior";

export default class ObjectFit extends Sb {
  constructor(el, props) {
    super();

    this.state = {
      loaded: false
    };

    this.image = el;
    this.props = props;
    this.src = "";

    // Bail immediately if object-fit is supported
    if (this.image.style.objectFit !== undefined) return;

    // If unsupported, check if image is already loaded and cached.
    // If so, update state immediately. Otherwise listen for image load.
    this.image.complete ? this.setState({ loaded: true }) : this.loadImage();
  }

  update = () => {
    if (!this.state.loaded) return;

    const src = this.getImageSrc();

    if (this.src !== src) {
      this.src = src;

      // construct element to house background image
      const bgImageEl = this.buildBgImageEl(src);

      // replace img element with bgImageEl
      const parentNode = this.image.parentNode;
      parentNode.replaceChild(bgImageEl, this.image);
    }
  };

  loadImage = () => {
    this.image.addEventListener("load", e => {
      this.setState({ loaded: true });
    });
  };

  buildBgImageEl(imageSrc) {
    const bgImageEl = document.createElement("div");

    bgImageEl.className = this.image.className;

    Object.assign(bgImageEl.style, {
      backgroundImage: `url("${imageSrc}")`,
      backgroundSize: "cover"
    });

    // If prop for objectPosition are passed,
    // apply them as backgroundPosition
    if (this.props.objectPosition) {
      const { x, y } = this.props.objectPosition;
      Object.assign(bgImageEl.style, { backgroundPosition: `${x} ${y}` });
    } else {
      Object.assign(bgImageEl.style, { backgroundPosition: `50% 50%` });
    }

    return bgImageEl;
  }

  getImageSrc() {
    // Attempt to get image from `srcset`, otherwise fallback to `src`
    return typeof this.image.currentSrc !== "undefined"
      ? this.image.currentSrc
      : this.image.src;
  }
}
